<template>
  <v-container fluid class="fill-height">
    <v-row class="pb-0" align="center" justify="center">
      <v-col>
        <br />
        <v-img :src="getImgUrl()" width="800px" class="ml-left mb-0" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <br />
        <br />
        <br />
        <p class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          Looking to improve your digital skills but don’t know where to start?
          Welcome to the launchpad for a successful future.
        </p>
        <p class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          Joining together north east Scotland’s leading educational
          establishments and the country's top digital training providers, the
          Future Skills Partnership web portal provides access to the latest in
          digital learning and education, giving you the tools required to
          improve your digital side – whether you are looking to boost your own
          CV or the services your company can offer.
        </p>
        <p class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          Together, the Future Skills Partnership is working to enhance the
          digital skills of people and businesses in the north east of Scotland
          and keep those talents in the region. The region has led the way in
          energy for many decades, and the pace of digital transformation within
          that sector and many others is accelerating rapidly presenting new
          opportunities.
        </p>
        <p class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          You’d be amazed at the range of jobs that now require some level of
          digital skill, and you can find all the information, news and events
          you need to get started right here.
        </p>
        <p class="pa-2" :style="$vuetify.theme.dark ? '' : 'color: #19365e; '">
          So, what are you waiting for? Get with the programme today!
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="end">
      <v-col cols="12">
        <v-card
          elevation="2"
          :color="$vuetify.theme.dark ? 'grey lighten-2' : ''"
          :class="isMobile ? 'mb-10 px-2' : ''"
        >
          <v-row align="center" justify="center">
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/onetechhub-logo.svg"
                alt=""
                width="120"
                @click="
                  goToProvider(
                    'https://www.opportunitynortheast.com/digital-entrepreneurship/one-tech-hub'
                  )
                "
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/sds-logo.png"
                alt=""
                width="120"
                @click="
                  goToProvider('https://www.skillsdevelopmentscotland.co.uk/')
                "
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/thedatalab-logo.png"
                alt=""
                width="60"
                @click="goToProvider('https://www.thedatalab.com/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/uoa-logo.png"
                alt=""
                contain
                width="130"
                @click="goToProvider('https://www.abdn.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img partner-logo-rgu"
                src="../../public/platform-logos/rgu-full-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://www.rgu.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/codeclan-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://codeclan.com/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/nescol-logo.png"
                alt=""
                width="130"
                @click="goToProvider('https://www.nescol.ac.uk/')"
              />
            </v-col>
            <v-col cols="6" lg="1">
              <v-img
                class="center-img"
                src="../../public/platform-logos/dw-logo.png"
                alt=""
                width="60"
                @click="goToProvider('https://www.digitalworld.net/industries-and-jobs')"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      images: ["homepage-image-1", "homepage-image-2", "homepage-image-3"],
      selectedImage: null,
    };
  },
  name: "Home",
  watch: {},
  computed: {
    isMobile() {
      if (window.innerWidth <= 1250) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToProvider(provider) {
      window.open(provider, "_blank");
    },
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
    getImgUrl() {
      var images = require.context(
        "../../public/homepage-images/",
        false,
        /\.jpg$/
      );
      return images("./" + this.selectedImage + ".jpg");
    },
  },
  created() {
    this.selectedImage = this.randomItem(this.images);
  },
  mounted() {},
};
</script>

<style scoped>
p {
  font-size: 1.3em;
  font-weight: bold;
  /* text-align: center; */
}
.partner-logo-rgu {
  background-color: #712177;
}
.center-img {
  margin: 0 auto;
  cursor: pointer;
}
</style>
